import { template as template_d62b958e51c44ef59281449a0450a8f2 } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import bodyClass from "discourse/helpers/body-class";
const AddCategoryTagClasses = template_d62b958e51c44ef59281449a0450a8f2(`
  {{#if @category}}
    {{bodyClass "category" (concat "category-" @category.fullSlug)}}
  {{/if}}

  {{#each @tags as |tag|}}
    {{bodyClass (concat "tag-" tag)}}
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AddCategoryTagClasses;
